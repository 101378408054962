import React from "react";
import Spinner from "../../Components/Spinner/Spinner";
import { useLocation } from "react-router-dom";

const ProfileDetails = ({ userData, Loading, error }) => {
  const localStorageUserData = JSON.parse(localStorage.getItem("userData"));
  const role = localStorageUserData?.role;
  const location = useLocation();
  const path = location.pathname;
  const pathName = path.split("/");

  console.log(pathName);

  if (Loading) {
    return (
      <div className="flex h-screen items-center justify-center text-center text-lg">
        <Spinner />
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex h-screen items-center justify-center text-center text-lg">
        {error}
      </div>
    );
  }

  if (!Loading && !userData) {
    return (
      <div className="flex h-screen items-center justify-center text-center text-lg">
        No data available
      </div>
    );
  }
  const basicInfo = userData?.profile?.basicInformation || {};

  return (
    <div className="font-jost mx-auto p-6">
      <div>
        {/* Personal Information */}
        <div className="mb-2 bg-white px-4">
          <h3 className="font-jost mb-2 text-xl text-textYellow">
            Personal Information
          </h3>
          <div className="flex  flex-col md:flex-row md:flex-wrap">
            <p className="mb-2 w-full md:w-1/2">
              <span className="font-medium">Name:</span>{" "}
              {userData?.name || "NA"}
            </p>
            <p className="mb-2 w-full md:w-1/2">
              <span className="font-medium">Age:</span> {userData?.age || "NA"}
            </p>
            <p className="mb-2 w-full md:w-1/2">
              <span className="font-medium">Gender:</span>{" "}
              {userData?.gender || "NA"}
            </p>
            <p className="mb-2 w-full md:w-1/2">
              <span className="font-medium">Profile Completion:</span>{" "}
              {userData?.profileCompletionPercentage}%
            </p>

            <p className="mb-2 w-full md:w-1/2">
              <span className="font-medium">Marital Status:</span>{" "}
              {userData?.maritalStatus || "NA"}
            </p>

            <p className="mb-2 w-full md:w-1/2">
              <span className="font-medium">DOB:</span>{" "}
              {new Date(userData?.dob).toLocaleDateString("en-CA") || "NA"}
            </p>
            <p className="mb-2 w-full md:w-1/2">
              <span className="font-medium">Profile Status:</span>{" "}
              {userData?.profileCompletionPercentage === 100 &&
              (!userData?.hasOwnProperty("isActive") || userData?.isActive)
                ? "Active"
                : "Inactive"}
            </p>

            {userData?.maritalStatus &&
              userData?.maritalStatus !== "never married" && (
                <p className="mb-2 w-full md:w-1/2">
                  <span className="font-medium">Children Count:</span>{" "}
                  {basicInfo?.childrenCount || "0"}
                </p>
              )}
          </div>
        </div>

        {/* Basic Information */}
        <div className="mb-2 bg-white px-4">
          <div className="flex flex-col md:flex-row">
            <div className="w-full md:w-1/2">
              <h3 className="font-jost mb-2 text-xl text-textYellow ">
                Basic Information
              </h3>
              <p className="mb-2">
                <span className="font-medium">Current Place:</span>{" "}
                {basicInfo.currentPlace || "NA"}
              </p>
              <p className="mb-2">
                <span className="font-medium">Current City:</span>{" "}
                {basicInfo.currentCity || "NA"}
              </p>
              <p className="mb-2">
                <span className="font-medium">State:</span>{" "}
                {basicInfo.state || "NA"}
              </p>
              <p className="mb-2">
                <span className="font-medium">Country:</span>{" "}
                {basicInfo.country || "NA"}
              </p>
              <p className="mb-2">
                <span className="font-medium">Nationality:</span>{" "}
                {basicInfo.nationality || "NA"}
              </p>
              <p className="mb-2">
                <span className="font-medium">Height:</span>{" "}
                {basicInfo?.height || "NA"}
              </p>
              <p className="mb-2">
                <span className="font-medium">Weight:</span>{" "}
                {basicInfo?.weight || "NA"}
              </p>
              <p className="mb-2">
                <span className="font-medium">Body Type:</span>{" "}
                {basicInfo?.bodyType || "NA"}
              </p>
              <p className="mb-2">
                <span className="font-medium">Mother Tongue:</span>{" "}
                {basicInfo?.motherTongue || "NA"}
              </p>
              <p className="mb-2">
                <span className="font-medium">Languages Can Speak:</span>{" "}
                {basicInfo?.languagesCanSpeak?.join(", ") || "NA"}
              </p>
              <p className="mb-2">
                <span className="font-medium">Medical History:</span>{" "}
                {basicInfo?.medicalHistory || "NA"}
              </p>
            </div>

            {/* Right - Spiritual Information */}
            <div className="w-full md:w-1/2">
              <h3 className="font-jost mb-2 text-xl text-textYellow ">
                Spiritual Information
              </h3>
              <p className="mb-2">
                <span className="font-medium">Initiated Name:</span>{" "}
                {basicInfo?.initiatedName || "NA"}
              </p>
              <p className="mb-2">
                <span className="font-medium">
                  No of rounds Hare Krishna Maha Mantra:
                </span>{" "}
                {userData?.countOfMantra || "0"}
              </p>
              <p className="mb-2">
                <span className="font-medium">Spiritual Status:</span>{" "}
                {basicInfo?.spiritualStatus || "NA"}
              </p>
              <p className="mb-2">
                <span className="font-medium">Spiritual Master:</span>{" "}
                {basicInfo?.spiritualMaster || "NA"}
              </p>
              <p className="mb-2">
                <span className="font-medium">Connected Temple:</span>{" "}
                {basicInfo?.connectedTemple || "NA"}
              </p>
              <p className="mb-2">
                <span className="font-medium">Four Regulative Principles:</span>{" "}
                {basicInfo?.fourRegulativePrinciples?.join(", ") || "NA"}
              </p>
            </div>
          </div>
        </div>

        {/* Contact Information */}
        {(role === "admin" ||
          pathName[1] === "accepted-users" ||
          pathName[1] === "profile") && (
          <div className="mb-2 bg-white px-4">
            <h3 className="font-jost mb-2 text-xl text-textYellow">
              Contact Information
            </h3>
            <div className="flex flex-col md:flex-row md:flex-wrap">
              <p className="mb-2 w-full md:w-1/2">
                <span className="font-medium">Phone:</span>{" "}
                {userData?.phone || "NA"}
              </p>
              <p className="mb-2 w-full md:w-1/2">
                <span className="font-medium">Created By:</span>{" "}
                {userData?.createdBy || "NA"}
              </p>
              <p className="mb-2 w-full md:w-1/2">
                <span className="font-medium">Email:</span>{" "}
                {userData?.primaryEmail || "NA"}
              </p>

              <p className="mb-2 w-full md:w-1/2">
                <span className="font-medium">Contact Person:</span>{" "}
                {userData?.contactPersonName || "NA"}
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProfileDetails;
